const clone = (object) => JSON.parse(JSON.stringify(object));

const flatten = (object, parentObject, result = {}) => {
  const resultCopy = result;

  for (const key in object) {
    if (Object.prototype.hasOwnProperty.call(object, key)) {
      const propName = parentObject
        ? `${parentObject}_${key}`
        : key;

      const isObject = Boolean(typeof object[key] === 'object');
      if (isObject) {
        flatten(object[key], propName, result);
      } else {
        resultCopy[propName] = object[key];
      }
    }
  }

  return resultCopy;
};

const isEqual = (initialState, currentState) => {
  const isInitialStateIsArray = Array.isArray(initialState);
  const isCurrentStateIsArray = Array.isArray(currentState);

  const flattenInitial = isInitialStateIsArray
    ? initialState
    : flatten(initialState);

  const flattenCurrent = isCurrentStateIsArray
    ? currentState
    : flatten(currentState);

  const initialReplacer = isInitialStateIsArray
    ? null
    : Object.keys(flattenInitial).sort();

  const currentReplacer = isCurrentStateIsArray
    ? null
    : Object.keys(flattenCurrent).sort();

  const initial = JSON.stringify(flattenInitial, initialReplacer);
  const current = JSON.stringify(flattenCurrent, currentReplacer);

  const isStatesEqual = Boolean(initial === current);

  return isStatesEqual;
};

const getFieldValueByPath = (object, path) => {
  const pathIsString = Boolean(typeof path === 'string');

  const isOneRootPath = Boolean(path.length === 1);
  const isZeroRootPath = Boolean(path.length === 0);

  if (pathIsString) {
    return getFieldValueByPath(object, path.split('.'));
  }

  if (isOneRootPath) {
    const [firstPathKey] = path;

    return object[firstPathKey];
  }

  if (isZeroRootPath) {
    return object;
  }

  const [firstPathKey] = path;

  return getFieldValueByPath(object[firstPathKey], path.slice(1));
};

export {
  clone,
  flatten,
  isEqual,
  getFieldValueByPath,
};
