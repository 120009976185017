import Vue from 'vue';
import VueTheMask from 'vue-the-mask';
import firebase from '@/services/firebase';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import '@/filters';

Vue.use(VueTheMask);

Vue.config.productionTip = false;

let app;

firebase.auth()
  .onAuthStateChanged(() => {
    if (!app) {
      app = new Vue({
        router,
        store,
        vuetify,
        render: (h) => h(App),
      }).$mount('#app');
    }
  });
