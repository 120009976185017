import Application from '@/models/Application';
import { isEqual, clone } from '@/utils/object';
import not from '@/utils/common';

const applicationModel = new Application();

const updateState = (state) => {
  state.selectedApplication = clone(state.selectedApplication);
  state.editedApplication = clone(state.editedApplication);

  applicationModel.application = clone(state.selectedApplication);
};

export default {
  state: {
    selectedApplication: {},

    selectedSubApplications: [],

    editedSubApplications: [],

    editedApplication: {},

    selectedSubApplication: {},

    editedSubApplication: {},

    complaineStatus: false,

    disabled: false,

    isCLient: false,

    isOwner: false,

    actualVersion: true,
  },

  getters: {
    APPLICATION: (state) => state.selectedApplication,

    SUB_APPLICATIONS: (state) => state.selectedSubApplications,

    EDITED_APPLICATION: (state) => state.editedApplication,

    APPLICATION_ID: (state) => state.selectedApplication?.id,

    CURRENT_EDIT_ROLE: (state) => state.selectedApplication?.data?.common?.edit,

    COMPLAIN_STATUS: (state) => state.complaineStatus,

    FIELDS_EXEPT_DISABLED: (state) => state.disabled || state.isOwner,

    FIELDS_DISABLED: (state) => state.disabled || state.isOwner || !state.actualVersion,

    IS_CLIENT: (state) => state.isCLient,

    ACTUAL_VERSION: (state) => state.actualVersion,

    IS_UNSAVED_SECTION_EXIST: (state) => {
      const isDataNotChanged = isEqual(state.selectedApplication, state.editedApplication);
      const isSubDataNotChanged = isEqual(state.selectedSubApplications,
        state.editedSubApplications);

      const isDataChanged = not(isDataNotChanged);
      const isSubDataChanged = not(isSubDataNotChanged);

      return isDataChanged || isSubDataChanged;
    },
  },

  actions: {
    async createApplication(_, parentApplicationId) {
      const application = await applicationModel.store(parentApplicationId);

      return application;
    },

    async toggleApplicationStatus(_, { id, parentId, status }) {
      const subApplication = await applicationModel.getApplicationById(id, parentId);

      const isApplicationExist = Boolean(subApplication !== null);
      if (isApplicationExist) {
        subApplication.id = id;
        subApplication.status = status;
      }

      return subApplication;
    },

    async getApplication({ commit, dispatch }, { id, parentId }) {
      const application = await applicationModel.getApplicationById(id, parentId);

      const isApplicationExist = Boolean(application !== null);
      if (isApplicationExist) {
        application.id = id;
      }

      dispatch('setSubApplicationChangeListener', id);

      commit('setApplication', application);
    },

    setApplicationChangeListener({ commit }, applicationId) {
      applicationModel.setApplicationChangeListener(applicationId, commit);
    },

    setSubApplicationChangeListener({ commit }, parentId) {
      applicationModel.setSubApplicationChangeListener(parentId, commit);
    },

    async storeSectionData({ commit }, {
      sectionKey, payload, subApplicant, id,
    }) {
      const response = await applicationModel.storeSection(sectionKey, payload, subApplicant, id);

      commit('setSectionData', {
        sectionKey,
        payload,
      });

      return response;
    },

    async storeEditSectionData({ commit }, {
      id, sectionKey, payload, subApplicant,
    }) {
      commit('setEditSectionData', {
        id,
        sectionKey,
        payload,
        subApplicant,
      });
    },

    async storeCommonData({ commit }, payload) {
      for (let i = 0; i < Object.keys(payload).length; i++) {
        const [key, value] = Object.entries(payload)[i];

        await applicationModel.storeCommonData(key, value);

        commit('setCommonData', {
          fieldKey: key,
          payload: value,
        });
      }
    },

    async saveApplication({ state, commit }) {
      await applicationModel
        .storeApplication(state.editedApplication, state.editedSubApplications, true);

      commit('saveApplication', state.editedApplication);
    },

    async saveAllData({ commit, dispatch }, payload) {
      commit('saveAllData', payload);
      await dispatch('saveApplication');
    },
  },

  mutations: {
    setCurrentVersion(state, payload) {
      state.actualVersion = payload;
    },

    setIsClient(state, payload) {
      state.isCLient = payload;
    },

    setIsOwner(state, payload) {
      state.isOwner = payload;
    },

    setFieldsDisabled(state, payload) {
      state.disabled = payload;
    },

    changeComplainStatus(state, payload) {
      state.complaineStatus = payload;
    },

    setAdminStatus(state, payload) {
      state.admin = payload;
    },

    saveAllData(state, payload) {
      state.selectedApplication.data = clone(payload);
      state.editedApplication.data = clone(payload);

      applicationModel.application.data = clone(payload);

      updateState(state);
    },

    setApplication(state, application) {
      state.selectedApplication = clone(application);
      state.editedApplication = clone(application);

      applicationModel.application = clone(application);
    },

    setSelectedSubApplications(state, subApplications) {
      state.selectedSubApplications = clone(subApplications);
      applicationModel.subApplications = clone(subApplications);

      state.editedSubApplications = clone(subApplications);
    },

    setSectionData(state, { sectionKey, payload }) {
      state.selectedApplication.data[sectionKey] = payload;
      state.editedApplication.data[sectionKey] = payload;

      updateState(state);
    },

    setEditSectionData(state, {
      id, sectionKey, payload, subApplicant,
    }) {
      if (!subApplicant) {
        state.editedApplication.data[sectionKey] = payload;
        state.editedApplication = clone(state.editedApplication);
      } else {
        const currentSubApps = state.editedSubApplications;

        const newSubApps = currentSubApps.map((item) => {
          if (item.id === id) {
            const newValue = item;
            newValue.data[sectionKey] = payload;

            return newValue;
          }
          return item;
        });

        state.editedSubApplications = newSubApps;
      }
    },

    setCommonData(state, { fieldKey, payload }) {
      state.selectedApplication[fieldKey] = payload;
      state.editedApplication[fieldKey] = payload;

      updateState(state);
    },

    saveApplication(state, application) {
      state.selectedApplication = clone(application);
    },
  },
};
