import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import Ripple from 'vuetify/lib/directives/ripple';

// Ripple is workaround for v-simple-checkbox error
// https://github.com/vuetifyjs/vuetify/issues/12224

Vue.use(Vuetify);

export default new Vuetify({
  directives: {
    Ripple,
  },
  options: { customProperties: true },
  theme: {
    themes: {
      light: {
        primary: '#1976D2',
        secondary: '#5CBBF6',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FB8C00',
      },
    },
  },
});
