import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

import {
  APPLICATION_STATUSES as STATUSES,
  APPLICATION_TYPES,
  COLLECTION_PATH,
  USER_ROLES,
} from './helpers/consts';

import applicationValues from './helpers';

class Application {
  application = {};

  subApplications = [];

  type = APPLICATION_TYPES.main;

  constructor(type = APPLICATION_TYPES.main) {
    const { defaultValues } = this;

    this.application.data = defaultValues;
    this.type = type;
    this.subType = COLLECTION_PATH.sub;
  }

  get type() {
    return this.type;
  }

  get isSubApplication() {
    const isSubApplication = Boolean(this.type === APPLICATION_TYPES.sub);

    return isSubApplication;
  }

  get defaultValues() {
    return (async () => {
      const defaultValues = await applicationValues();

      const values = this.isSubApplication
        ? defaultValues.subApplicationDefaultValues
        : defaultValues.applicationDefaultValues;

      return values;
    })();
  }

  get application() {
    return this.application;
  }

  get subApplications() {
    return this.subApplications;
  }

  get dataCollection() {
    return COLLECTION_PATH[this.type] ?? COLLECTION_PATH.main;
  }

  get isAdviserCanEdit() {
    const isAdviserCanEdit = Boolean(this.application.data.edit === USER_ROLES.adviser);

    return isAdviserCanEdit;
  }

  set application(application) {
    this.application = application;
  }

  set subApplications(subApplications) {
    this.subApplications = subApplications;
  }

  async getApplicationById(applicationId, parentId = null) {
    const isSubApplication = Boolean(parentId);
    this.type = isSubApplication
      ? APPLICATION_TYPES.sub
      : APPLICATION_TYPES.main;

    try {
      const getApplication = async (id) => {
        const response = await firebase.firestore()
          .collection(this.dataCollection)
          .doc(id)
          .get();

        return response.data();
      };

      const getSubApplication = async (id, parent) => {
        const response = await firebase.firestore()
          .collection(this.dataCollection)
          .doc(id)
          .get();

        const application = response.data();

        const isNotValidApplication = Boolean(application?.parentId !== parent);
        if (isNotValidApplication) {
          return null;
        }

        return application;
      };

      if (this.isSubApplication) {
        return await getSubApplication(applicationId, parentId);
      }

      return await getApplication(applicationId);
    } catch (error) {
      console.error(error);
    }

    return null;
  }

  setApplicationChangeListener(applicationId, commit) {
    try {
      firebase.firestore()
        .collection(this.dataCollection)
        .doc(applicationId)
        .onSnapshot((response) => {
          const application = response.data();
          application.id = applicationId;

          // todo: add confirm update
          commit('setApplication', application);
        });
    } catch (error) {
      console.error(error);
    }
  }

  setSubApplicationChangeListener(parentId, commit) {
    try {
      firebase.firestore()
        .collection(this.subType)
        .where('parentId', '==', parentId)
        .onSnapshot((response) => {
          const subApplications = response.docs.map((application) => {
            const data = {
              ...application.data(),
              ...{
                id: application?.id,
              },
            };
            return data;
          });

          commit('setSelectedSubApplications', subApplications);
        });
    } catch (error) {
      console.error(error);
    }
  }

  async store(parentId = null) {
    const currentUserName = firebase.auth().currentUser.displayName;
    const userId = firebase.auth().currentUser.uid;
    const createdAt = firebase.firestore.FieldValue.serverTimestamp();

    const isSubApplication = Boolean(parentId);

    this.type = isSubApplication
      ? APPLICATION_TYPES.sub
      : APPLICATION_TYPES.main;

    const defaultValues = await this.defaultValues;

    const applicationPayload = {
      adviser: userId,
      created: createdAt,
      data: defaultValues,
      author: currentUserName,
    };

    applicationPayload.data.common.adviser = {
      id: userId,
      name: currentUserName,
    };

    if (isSubApplication) {
      applicationPayload.status = STATUSES.active;
      applicationPayload.parentId = parentId;
    }

    this.application = applicationPayload;

    try {
      const docRef = await firebase.firestore()
        .collection(this.dataCollection)
        .add(this.application);

      await firebase.firestore()
        .collection(this.dataCollection)
        .doc(docRef?.id)
        .update({ id: docRef?.id });

      this.application.id = docRef?.id;

      return this.application;
    } catch (error) {
      console.error(error);

      return null;
    }
  }

  async storeApplication(applicationPayload, subApplicationPayload, globalSave) {
    await this.storeData(applicationPayload, subApplicationPayload, globalSave);
  }

  async storeCommentData(applicationPayload) {
    await this.storeComment(applicationPayload);
  }

  async storeSection(section, sectionPayload, subApplicant, id) {
    const collectionData = {
      data: {
        [section]: sectionPayload,
      },
      subApplicant,
      id,
    };

    await this.storeData(collectionData);
  }

  async storeCommonData(key, payload) {
    const collectionData = {
      [key]: payload,
    };

    await this.storeData(collectionData);
  }

  async storeData(collectionData, subCollectionData, globalSave = false) {
    const newCollectionData = JSON.parse(JSON.stringify(collectionData));

    if (!newCollectionData.subApplicant) {
      delete newCollectionData.data.personal_details;
      delete newCollectionData.data.employments;
      delete newCollectionData.data.address_history;
    }

    const saveOptions = {
      merge: true,
    };

    const isSubApplicant = Boolean(newCollectionData.subApplicant);

    this.type = isSubApplicant ? APPLICATION_TYPES.sub : APPLICATION_TYPES.main;

    let appId;

    if (globalSave) {
      appId = newCollectionData.id;
    } else {
      appId = isSubApplicant ? newCollectionData.id : this.application.id;
    }

    try {
      await firebase.firestore()
        .collection(this.dataCollection)
        .doc(appId)
        .set(newCollectionData, saveOptions);

      if (subCollectionData) {
        subCollectionData.forEach((subApplicant) => {
          firebase.firestore()
            .collection(COLLECTION_PATH.sub)
            .doc(subApplicant.id)
            .set(subApplicant, saveOptions);
        });
      }
    } catch (error) {
      console.error(error);
    }
  }
}

export default Application;
