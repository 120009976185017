var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', [_c('div', {
    staticClass: "login-container"
  }, [_c('div', {
    staticClass: "login-form"
  }, [_vm.mfa ? _c('div', {
    attrs: {
      "id": "recaptcha-container"
    }
  }) : _vm._e(), _vm.mfa ? _c('BaseLoginMFA', {
    attrs: {
      "resolver": _vm.resolver
    },
    on: {
      "error": _vm.mfaError
    }
  }) : _c('BaseLoginEmailPassword', {
    attrs: {
      "loading": _vm.loading
    },
    on: {
      "login": _vm.loginEmail
    }
  }), _c('v-alert', {
    staticClass: "mt-5",
    attrs: {
      "type": "error",
      "outlined": "",
      "dismissible": ""
    },
    model: {
      value: _vm.error,
      callback: function callback($$v) {
        _vm.error = $$v;
      },
      expression: "error"
    }
  }, [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")])], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }