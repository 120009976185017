import {
  APPLICATION_STATUSES,
  USER_ROLES,
  actualAppVersion,
  SETTINGS,
} from './consts';

import { getSettings } from '@/services/firebase';

export default (async () => {
  let settings;

  try {
    const res = await getSettings();
    settings = res.settings;
  } catch (error) {
    settings = SETTINGS;
  }

  const applicationDefaultValues = {
    appVersion: actualAppVersion,

    common: {
      adviser: {},
      client: '',
      status: APPLICATION_STATUSES.new,
      view: USER_ROLES.adviser,
      edit: USER_ROLES.adviser,
    },

    companies: [],

    comments: {
      common: '',
      sub_application: '',
      companies: '',
      funding_details: '',
      insurance: '',
      future_goals: '',
      dependants: '',
      real_estate_assets: '',
      incomes: '',
      other_assets: '',
      liabilities: '',
      living_expenses: '',
      retirement: '',
      lender: '',
      loan_structure: '',
      summary: '',
      lending_notes: '',
      complain: '',
      checklist: '',
      files: '',
      final_statement: '',
      compliance_check_list: '',
    },

    summary: {
      lending_required: '0',
    },

    funding_details: {
      lending_purpose: [],
      property_purpose: '',
      valuation_basis: '',
      property_address: '',
      deposit_source: [],
      expect_rental: '',

      // New

      toggle: {
        newPurсhase: false,
        debt: false,
        refinance: false,
        topUp: false,
        loan: false,
        deposit: false,
      },

      topUp: [],

      refinanceEditCheck: [],

      loan: [
        {
          amount: '',
          terms: settings.terms,
        },
      ],

      debt: [
        {
          amount: '',
          adjustment: '',
          term: settings.terms,
          comment: '',
        },
      ],

      new_purchase: [
        {
          estimated_price: '',
          terms: '',
          property_status: '',
          land_value: '',
          costs: '',
        },
      ],
    },

    insurance: {
      live: '',
      content: '',
      comment_family: '',
      comment_home: '',
    },

    future_goals: {
      comment: '',
    },

    dependants: [],

    real_estate_assets: [],

    incomes: {
      rental: [],
      employee: [],
      other: [],
    },

    other_assets: [],

    liabilities: {
      items: [],
      guarantor: false,
      guarantor_comment: '',
      credit_cards: [],
      mortgages: [],
    },

    living_expenses: [
      {
        description: 'Rent',
        amount: '',
      },
      {
        description: 'Body Corporate Fees',
        amount: '',
      },
      {
        description: 'Child support/maintance',
        amount: '',
      },
      {
        description: 'KiwiSaver',
        amount: '',
      },
      {
        description: 'Food',
        amount: '',
      },
      {
        description: 'Utilities',
        amount: '',
      },
      {
        description: 'Rates',
        amount: '',
      },
      {
        description: 'Transport',
        amount: '',
      },
      {
        description: 'Ongoing Household Expenses',
        amount: '',
      },
      {
        description: 'Childcare',
        amount: '',
      },
      {
        description: 'Healthcare/Medical Care',
        amount: '',
      },
      {
        description: 'Personal Care and Clothing',
        amount: '',
      },
      {
        description: 'Public education',
        amount: '',
      },
      {
        description: 'Private education',
        amount: '',
      },
      {
        description: 'House and Contents insurance',
        amount: '',
      },
      {
        description: 'Vehicle Insurance',
        amount: '',
      },
      {
        description: 'Health/ Medical/ Funeral/ Pet Insurance',
        amount: '',
      },
      {
        description: 'Life and Disability Insurance',
        amount: '',
      },
      {
        description: 'Savings/ Investments',
        amount: '',
      },
      {
        description: 'Entertainmant and Recreation',
        amount: '',
      },
      {
        description: 'Tithing',
        amount: '',
      },
      {
        description: 'Other',
        amount: '',
      },
    ],

    retirement: {
      anticipate: false,
      comment: '',
      during_loan: false,
      repay: null,
    },

    lender: {
      name: '',
      rate: settings.fixed_rate,
      stress_rate: settings.stress_rate,
    },

    loan_structure: [],

    lending_notes: {
      purpose: '',
      backgrounds: '',
      other: '',
    },

    checklist: {
      apps: {},
      rental: [],
      creditCards: [],
      liabilities: [],
      other: [],
    },

    complianceCheckList: {
      isAnalysed: '',
      isExplained: '',
      isNotesUpToDate: '',
      isSOA: '',
      isConsented: '',
      isComplaint: '',
    },
  };

  const subApplicationDefaultValues = {
    common: {
      edit: USER_ROLES.adviser,
    },

    personal_details: {
      last_name: '',
      first_name: '',
      middle_name: '',
      preffered_name: '',
      app_title: '',
      birth_date: '',
      marital_status: '',
      gender: '',
      first_home_buyer: '',
      nz_residency_status: '',
      citizenship: [],
      country_of_residence: '',
      country_of_birth: '',
      email: '',
      phone: '',
      bunkruptcy: '',
      bunkruptcy_comment: '',
      adviser_comment: '',
    },

    employments: [],

    address_history: [],

    comments: {
      personal_details: '',
      employments: '',
      address_history: '',
    },
  };

  return {
    applicationDefaultValues,
    subApplicationDefaultValues,
  };
});
