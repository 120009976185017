import Vue from 'vue';
import Vuex from 'vuex';
import notifications from '@/store/modules/notifications.store';
import application from '@/store/modules/application';
import users from '@/store/modules/users.store';
import kanbanBoard from '@/store/modules/kanbanBoard';
import navigation from '@/store/modules/navigation';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    application,
    notifications,
    users,
    kanbanBoard,
    navigation,
  },
});
