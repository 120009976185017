/* Actual Version is 2 */

const actualAppVersion = '2';

const SETTINGS = {
  advisersCount: '1',
  fixed_rate: '4.44',
  stress_rate: '6.66',
  terms: '10',
};

const USER_ROLES = {
  adviser: 'adviser',
  client: 'client',
};

const AVAILABLE_USER_ROLES = [
  USER_ROLES.adviser,
  USER_ROLES.client,
];

const APPLICATION_STATUSES = {
  new: 'new',
  draft: 'draft',
  active: 'active',
  completed: 'completed',
  submitted: 'submitted',
  approval: 'approval',
  confirmed: 'confirmed',
  settled: 'settled',
  finalized: 'finalized',
};

const APPLICATION_TYPES = {
  main: 'main',
  sub: 'sub',
};

const COLLECTION_PATH = {
  main: 'applications',
  sub: 'sub_applications',
};

export {
  SETTINGS,
  USER_ROLES,
  AVAILABLE_USER_ROLES,
  APPLICATION_TYPES,
  APPLICATION_STATUSES,
  COLLECTION_PATH,
  actualAppVersion,
};
