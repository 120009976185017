import Vue from 'vue';

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

import {
  updateApplicationMeta,
} from '@/services/firebase';

import { APPLICATION_STATUSES, USER_ROLES, COLLECTION_PATH } from '@/models/helpers/consts';

const filterApplicationByStatus = (application, status) => {
  const isApplicationHaveStatus = Boolean(application?.data?.data?.common?.status === status);

  return isApplicationHaveStatus;
};

export default {
  state: {
    applications: [],
  },

  getters: {
    APPLICATIONS: (state) => state.applications,

    APPLICATION_LAST_ID: () => {},

    APPLICATIONS_NEW: (state) => {
      const newApplications = state.applications
        .filter((app) => filterApplicationByStatus(app, APPLICATION_STATUSES.new));

      return newApplications;
    },

    APPLICATIONS_COMPLETED: (state) => {
      const newApplications = state.applications
        .filter((app) => filterApplicationByStatus(app, APPLICATION_STATUSES.completed));

      return newApplications;
    },

    APPLICATIONS_SUBMITTED: (state) => {
      const newApplications = state.applications
        .filter((app) => filterApplicationByStatus(app, APPLICATION_STATUSES.submitted));

      return newApplications;
    },

    APPLICATIONS_APPROVAL: (state) => {
      const newApplications = state.applications
        .filter((app) => filterApplicationByStatus(app, APPLICATION_STATUSES.approval));

      return newApplications;
    },

    APPLICATIONS_CONFIRMED: (state) => {
      const newApplications = state.applications
        .filter((app) => filterApplicationByStatus(app, APPLICATION_STATUSES.confirmed));

      return newApplications;
    },

    APPLICATIONS_SETTLED: (state) => {
      const newApplications = state.applications
        .filter((app) => filterApplicationByStatus(app, APPLICATION_STATUSES.settled));

      return newApplications;
    },

    APPLICATIONS_FINALIZED: (state) => {
      const newApplications = state.applications
        .filter((app) => filterApplicationByStatus(app, APPLICATION_STATUSES.finalized));

      return newApplications;
    },
  },

  actions: {
    async getApplications({ commit }, adviserId) {
      const adviserToken = await firebase.auth().currentUser.getIdTokenResult();
      const isAdmin = await adviserToken?.claims?.admin;

      const formatApplicationData = (app) => {
        const formattedData = {
          id: app?.id,
          data: app.data(),
        };

        return formattedData;
      };

      if (isAdmin) {
        const response = await firebase.firestore().collection('applications').get();
        const applications = response.docs.map(formatApplicationData);
        commit('setApplications', applications);
      } else {
        firebase.firestore()
          .collection(COLLECTION_PATH.main)
          .where('adviser', '==', adviserId)
          .onSnapshot((response) => {
            const applications = response.docs.map(formatApplicationData);
            commit('setApplications', applications);
          });
      }
    },

    async changeApplicationStatus({ commit }, { applicationId, oldStatus, newStatus }) {
      const meta = {
        status: newStatus,
      };

      const needUpdateMeta = Boolean(oldStatus === APPLICATION_STATUSES.new
        && newStatus !== APPLICATION_STATUSES.new);

      if (needUpdateMeta) {
        meta.view = USER_ROLES.adviser;
        meta.edit = USER_ROLES.adviser;
      }

      const commitPayload = {
        applicationId, oldStatus, newStatus, meta,
      };

      commit('changeApplicationMeta', commitPayload);

      await updateApplicationMeta(meta, applicationId);
    },
  },

  mutations: {
    setApplications(state, applications) {
      state.applications = applications;
    },

    setAllApplications(state, applications) {
      state.allApplications = applications;
    },

    changeApplicationMeta(state, { applicationId, meta }) {
      const findApplicationById = (application) => application.id === applicationId;

      const application = state.applications.find(findApplicationById);

      const isApplicationNotFound = Boolean(!application);
      if (isApplicationNotFound) {
        return;
      }

      application.data.data.common.status = meta.status;
      application.data.data.common.view = meta.view;
      application.data.data.common.edit = meta.edit;

      Vue.set(application.data.data, 'common', application.data.data.common);
    },
  },
};
