export default {
  state: {
    messageListener: undefined,
    lastNotification: null,
  },
  getters: {
    GET_LAST_NOTIFICATION: (state) => state.lastNotification,
  },
  actions: {
    unsubscribeMessageListener(state) {
      state.state.messageListener();
    },
  },
  mutations: {
    SET_MESSAGE_LISTENER(state, data) {
      state.messageListener = data;
    },
    SET_LAST_NOTIFICATION(state, data) {
      state.lastNotification = data;
    },
  },
};
