<template>
  <div>
    <BaseLoginSMS @confirm="loginMFA" :loading="loading"/>
  </div>
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/auth';

import BaseLoginSMS from '@/components/BaseLoginSMS.vue';
import { getUserInfo, getCurrentUser } from '@/services/firebase';

export default {
  props: {
    resolver: {
      type: Object,
      required: true,
    },
  },
  components: { BaseLoginSMS },
  name: 'BaseLoginMFA',
  data() {
    return {
      loading: false,
      applicationVerifier: undefined,
    };
  },
  mounted() {
    if (this.resolver.hints.length === 1) {
      this.stage = 'code';
      this.sendCode(0);
      console.log('Sending code on number:', this.resolver.hints[0].phoneNumber);
    }
  },
  methods: {
    sendCode(selectedIndex) {
      if (this.resolver.hints[selectedIndex].factorId
        === firebase.auth.PhoneMultiFactorGenerator.FACTOR_ID) {
        const phoneInfoOptions = {
          multiFactorHint: this.resolver.hints[selectedIndex],
          session: this.resolver.session,
        };
        const applicationVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', { size: 'invisible' });
        const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
        phoneAuthProvider
          .verifyPhoneNumber(phoneInfoOptions, applicationVerifier)
          .then((verificationId) => {
            this.verificationId = verificationId;
          })
          .catch((e) => {
            console.error('Send code error:', e);
            this.$emit('error', e.message);
          });
        this.stage = 'code';
      } else {
        console.error('Unsupported second factor.');
      }
    },
    loginMFA(verificationCode) {
      this.loading = true;
      if (!this.verificationId) {
        this.$emit('error', 'Login error');
        return;
      }
      const cred = firebase.auth.PhoneAuthProvider.credential(
        this.verificationId, verificationCode,
      );
      const multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(cred);

      this.resolver.resolveSignIn(multiFactorAssertion)
        .then(() => {
          this.openDashboard();
        })
        .catch((e) => {
          console.error('loginMFA error', e.message);
          this.$emit('error', e.message);
        });
    },
    openDashboard() {
      const currentUser = getCurrentUser();
      this.loading = true;
      getUserInfo(currentUser.uid)
        .then((userInfo) => {
          if (userInfo.data().role === 'client') {
            this.$router.push({ name: 'ClientApplications' });
          } else if (userInfo.data().role === 'adviser') {
            this.$router.push({ name: 'AdviserCRM' });
          }
        });
    },
  },
};
</script>

<style scoped>

</style>
