var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    }
  }, [_c('h3', {
    staticClass: "login-header",
    attrs: {
      "data-cy": "login-header"
    }
  }, [_vm._v("LOGIN")]), _c('v-text-field', {
    attrs: {
      "data-cy": "email",
      "label": "Enter your e-mail address",
      "rules": _vm.emailRules,
      "required": ""
    },
    model: {
      value: _vm.form.email,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "email", $$v);
      },
      expression: "form.email"
    }
  }), _c('v-text-field', {
    attrs: {
      "data-cy": "password",
      "label": "Enter your password",
      "rules": _vm.passwordRules,
      "type": "password",
      "required": ""
    },
    model: {
      value: _vm.form.password,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "password", $$v);
      },
      expression: "form.password"
    }
  }), _c('v-layout', {
    attrs: {
      "justify-space-between": ""
    }
  }, [_c('v-btn', {
    attrs: {
      "loading": _vm.loading,
      "color": "primary",
      "data-cy": "submit"
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("Login ")]), _c('a', {
    attrs: {
      "href": ""
    }
  }, [_vm._v("Forgot Password")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }