import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '@/views/LoginPage.vue';
import { getCurrentUser } from '@/services/firebase';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Main',
    redirect: '/login',
  },
  {
    path: '/client',
    name: 'DashboardClient',
    meta: { requiresAuth: true },
    component: () => import('../views/DashboardClientLayout.vue'),
    children: [
      {
        path: 'app',
        name: 'ClientApplications',
        meta: { requiresAuth: true },
        props: true,
        component: () => import('../views/DashboardClient.vue'),
      },
      {
        path: 'app/:id',
        name: 'Application',
        meta: { requiresAuth: true },
        props: true,
        component: () => import('../views/ApplicationClientPage.vue'),
      },
      {
        path: 'print/:id',
        name: 'ApplicationClientPrint',
        meta: { requiresAuth: true },
        props: true,
        component: () => import('../views/ApplicationClientPrint.vue'),
      },
    ],
  },
  {
    path: '/adviser',
    name: 'DashboardAdviser',
    meta: { requiresAuth: true },
    component: () => import('../views/DashboardAdviserLayout.vue'),
    children: [
      {
        path: 'crm',
        name: 'AdviserCRM',
        meta: { requiresAuth: true },
        props: true,
        component: () => import('../views/DashboardAdviser.vue'),
      },
      {
        path: 'clients',
        name: 'UsersIndex',
        meta: { requiresAuth: true },
        props: true,
        component: () => import('../views/UsersIndex.vue'),
      },
      {
        path: 'profile',
        name: 'AdviserProfile',
        meta: { requiresAuth: true },
        props: true,
        component: () => import('../views/AdviserProfile.vue'),
      },
      {
        path: 'adviserslist',
        name: 'AdvisersIndex',
        meta: { requiresAuth: true },
        props: true,
        component: () => import('../views/AdvisersIndex.vue'),
      },
      {
        path: 'newadviser',
        name: 'AdviserAdd',
        meta: { requiresAuth: true },
        props: true,
        component: () => import('../views/AdviserAdd.vue'),
      },
      {
        path: 'adviserslist/:uid',
        name: 'AdviserEdit',
        meta: { requiresAuth: true },
        props: true,
        component: () => import('../views/AdviserEdit.vue'),
      },
      {
        path: 'settings',
        name: 'Settings',
        meta: { requiresAuth: true },
        props: true,
        component: () => import('../views/Settings.vue'),
      },
      {
        path: 'clients/:uid',
        name: 'UserEdit',
        meta: { requiresAuth: true },
        props: true,
        component: () => import('../views/UserEdit.vue'),
      },
      {
        path: 'newuser',
        name: 'UserAdd',
        meta: { requiresAuth: true },
        props: true,
        component: () => import('../views/UserAdd.vue'),
      },
      {
        path: 'application/:parentId/:id',
        name: 'SubApplicationAdviser',
        meta: { requiresAuth: true },
        props: true,
        component: () => import('../views/SubApplicationAdviserPage.vue'),
      },

      {
        path: 'application/:id',
        name: 'ApplicationAdviser',
        meta: { requiresAuth: true },
        props: true,
        component: () => import('../views/ApplicationAdviserPage.vue'),
      },
      {
        path: 'print/:id',
        name: 'ApplicationAdviserPrint',
        meta: { requiresAuth: true },
        props: true,
        component: () => import('../views/ApplicationAdviserPrint.vue'),
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const loggedIn = getCurrentUser();
  if (to.matched.some((record) => record.meta.requiresAuth) && !loggedIn) {
    console.log('No user', loggedIn);
    next('/login');
  }
  next();
});

export default router;
