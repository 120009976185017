<template>
  <router-view/>
</template>

<script>

export default {

};
</script>

<style lang="scss">
  @import '@/assets/style/global.scss';
</style>
