<template>
  <div>
    <h3 class="login-header">Enter the code from message</h3>

    <v-form ref="form" @submit.prevent="submit">
      <v-text-field
        label="6-digits code"
        v-model="code"
        required
      ></v-text-field>
      <v-layout justify-space-between>
        <v-btn color="primary" type="submit" :loading="loading">Confirm</v-btn>
      </v-layout>
    </v-form>
  </div>
</template>

<script>
export default {
  name: 'BaseLoginSMS',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      code: '',
    };
  },
  methods: {
    submit() {
      this.$emit('confirm', this.code);
    },
  },
};
</script>

<style scoped>
.login-header {
  color: #1867C0;
}
</style>
