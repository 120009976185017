import Vue from 'vue';

export default {
  state: {
    isNavigationOpen: false,
    selectedSection: {},
  },

  getters: {
    IS_NAVIGATION_OPEN: (state) => state.isNavigationOpen,

    SELECTED_SECTION_ID: (state) => (applicationId) => state.selectedSection?.[applicationId],
  },

  actions: {
    toggleNavigation({ commit }, navigationShowStatus) {
      commit('toggleNavigation', navigationShowStatus);
    },

    selectSection({ commit }, { applicationId, sectionId }) {
      commit('selectSection', { applicationId, sectionId });
    },
  },

  mutations: {
    toggleNavigation(state, navigationShowStatus) {
      state.isNavigationOpen = navigationShowStatus;
    },

    selectSection(state, { applicationId, sectionId }) {
      Vue.set(state.selectedSection, applicationId, sectionId);
    },
  },
};
