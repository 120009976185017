<template>
  <v-app>
    <div class="login-container">
      <div class="login-form">
        <div v-if="mfa" id="recaptcha-container" />
        <BaseLoginMFA
          v-if="mfa"
          :resolver="resolver"
          @error="mfaError"
        />
        <BaseLoginEmailPassword
          v-else
          @login="loginEmail"
          :loading="loading"
        />

        <v-alert
          class="mt-5"
          type="error"
          outlined
          v-model="error"
          dismissible
        >
          {{ errorMessage }}
        </v-alert>
      </div>
    </div>
  </v-app>
</template>

<script>
import firebase, { getUserInfo, getCurrentUser } from '@/services/firebase';
import BaseLoginEmailPassword from '@/components/BaseLoginEmailPassword.vue';
import BaseLoginMFA from '@/components/BaseLoginMFA.vue';

export default {
  components: {
    BaseLoginEmailPassword,
    BaseLoginMFA,
  },
  data() {
    return {
      loading: false,
      errorMessage: null,
      error: false,
      mfa: false,
      resolver: undefined,
    };
  },
  methods: {
    mfaError(errorMessage) {
      this.error = true;
      this.errorMessage = errorMessage;
      this.mfa = false;
      this.loading = false;
    },
    loginEmail(form) {
      this.errorMessage = null;
      this.error = false;
      this.loading = true;
      firebase.auth()
        .signInWithEmailAndPassword(form.email, form.password)
        .then(() => {
          this.openDashboard();
        })
        .catch((error) => {
          if (error.code === 'auth/multi-factor-auth-required') {
            this.resolver = error.resolver;
            this.mfa = true;
            return;
          }
          this.error = true;
          console.error('Login error:', error.message);
          this.errorMessage = error.message;
          this.stage = 'email';
          this.loading = false;
        });
    },
    openDashboard() {
      const currentUser = getCurrentUser();
      getUserInfo(currentUser.uid)
        .then((userInfo) => {
          if (userInfo.data().role === 'client') {
            this.$router.push({ name: 'ClientApplications' });
          } else if (userInfo.data().role === 'adviser') {
            this.$router.push({ name: 'AdviserCRM' });
          }
        });
    },
  },
};

</script>

<style scoped>
.login-container {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background: url("/img/login-background.jpg");
  background-size: cover;
}

.login-form {
  width: 410px;
  height: 210px;
  /*background-color: red;*/
}
</style>
