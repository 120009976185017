import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/app-check';
import 'firebase/firestore';
import 'firebase/storage';

import { UUIDGenerator } from '@/services/utils';
import { APPLICATION_STATUSES, COLLECTION_PATH, USER_ROLES } from '../models/helpers/consts';

export const FILE_STATUS = {
  active: 'active',
  archive: 'archive',
};

export default firebase
  .initializeApp(
    {
      apiKey: `${process.env.VUE_APP_API_KEY}`,
      authDomain: `${process.env.VUE_APP_AUTH_DOMAIN}`,
      projectId: `${process.env.VUE_APP_PROJECT_ID}`,
      storageBucket: `${process.env.VUE_APP_STORAGE_BUCKET}`,
      messagingSenderId: `${process.env.VUE_APP_MESSAGING_SENDER_ID}`,
      appId: `${process.env.VUE_APP_ID}`,
    },
  );

if (process.env.NODE_ENV === 'development') {
  console.warn('Using Firebase emulators');
  // experimentalForceLongPolling is only for Cypress
  // https://stackoverflow.com/questions/59336720/cant-use-cypress-to-test-app-using-using-firestore-local-emulator
  firebase.firestore()
    .settings({ experimentalForceLongPolling: true });

  firebase.auth()
    .useEmulator('http://localhost:9099');
  firebase.firestore()
    .useEmulator('localhost', 8080);
  firebase.storage()
    .useEmulator('localhost', 9199);
} else {
  const appCheck = firebase.appCheck();
  appCheck.activate(`${process.env.VUE_APP_CHECK}`, true);
}

export async function copyApplicationData(applicationIdFrom, collectionTo, parentId) {
  const docRef = firebase.firestore().collection(collectionTo).doc(applicationIdFrom);

  const docData = await docRef
    .get()
    .then((doc) => doc.exists && doc.data())
    .catch((error) => {
      console.error(error);
    });

  if (docData) {
    const newDocRef = firebase.firestore().collection(collectionTo).doc();

    if (parentId) {
      docData.parentId = parentId;
    }

    await newDocRef
      .set(docData)
      .catch((error) => {
        console.error(error);
      });

    return newDocRef.id;
  }

  return false;
}

export async function setApplicantStatus(application) {
  await firebase.firestore()
    .collection('sub_applications').doc(application.id).update(application);
}

export async function getSubApplications(applicationId) {
  const subAppsRef = await firebase.firestore()
    .collection('sub_applications')
    .where('parentId', '==', applicationId)
    .get();

  const subAppsDoc = subAppsRef.docs.map((doc) => doc.data());

  return subAppsDoc;
}

export function getUserInfo(uid) {
  return firebase.firestore()
    .collection('users')
    .doc(uid)
    .get();
}

export function getAdviserClients(adviserId) {
  return firebase.firestore()
    .collection('users')
    .where('adviser', '==', adviserId)
    .get();
}

export function updateUserInfo(uid, updateRequest) {
  const request = updateRequest;
  request.recovered = false;
  return firebase.firestore()
    .collection('users')
    .doc(uid)
    .update(request);
}

export function sendPasswordResetEmail(email) {
  return firebase.auth()
    .sendPasswordResetEmail(email, null);
}

export function getCurrentUser() {
  return firebase.auth().currentUser;
}

export function getCurrentUserId() {
  return firebase.auth().currentUser.uid;
}

export async function getAdvisers() {
  const formatApplicationData = (app) => {
    const data = app.data();

    const formattedData = {
      id: app?.id,
      name: data.auth.displayName,
    };

    return formattedData;
  };

  const advisersRef = await firebase.firestore().collection('users').where('role', '==', 'adviser').get();
  const advisersData = advisersRef.docs.map(formatApplicationData);

  return advisersData;
}

export async function getAdminRole() {
  const adviserToken = await firebase.auth().currentUser.getIdTokenResult();
  const isAdmin = await adviserToken?.claims?.admin;

  return isAdmin;
}

export function logout() {
  return firebase.auth()
    .signOut();
}

export function getUserApplications(uid) {
  return firebase.firestore()
    .collection(COLLECTION_PATH.main)
    .where('data.common.client.uid', '==', uid)
    .where('data.common.status', '==', APPLICATION_STATUSES.new)
    .where('data.common.view', '==', 'both')
    .orderBy('created')
    .get();
}

export async function getAdviserApplications(adviserId) {
  const applications = await firebase.firestore()
    .collection('applications')
    .where('adviser', '==', adviserId)
    .get();

  return applications;
}

export async function setApplicationAdviser(applicationId, adviserId) {
  await firebase.firestore()
    .collection('applications')
    .doc(applicationId)
    .update({ adviser: adviserId });

  const subAppsRef = await firebase.firestore()
    .collection('sub_applications')
    .where('parentId', '==', applicationId)
    .get();

  subAppsRef.docs.forEach((ref) => {
    firebase.firestore()
      .collection('sub_applications')
      .doc(ref.id)
      .update({ adviser: adviserId });
  });
}

export function writeDocument(applicationData, collectionPath, applicationId) {
  const docRef = firebase.firestore()
    .collection(collectionPath)
    .doc(applicationId);
  return {
    docRef,
    promise: docRef.set(applicationData),
  };
}

export async function updateApplicationMeta(meta, applicationId) {
  const saveOptions = {
    merge: true,
  };

  const collectionData = {
    data: {
      common: meta,
    },
  };

  try {
    await firebase.firestore()
      .collection(COLLECTION_PATH.main)
      .doc(applicationId)
      .set(collectionData, saveOptions);
  } catch (error) {
    console.error(error);
  }
}

export async function uploadFile({ file, metadata, applicationId }) {
  const filename = UUIDGenerator();

  const filenameParts = file.name.split('.');
  const ext = filenameParts[filenameParts.length - 1];

  const ref = `/${applicationId}/${filename}.${ext}`;

  const upload = await firebase.storage()
    .ref(ref)
    .put(file, metadata);

  return upload;
}

export function uploadToComplainFile({ file, metadata, applicationId }) {
  const filename = UUIDGenerator();

  const filenameParts = file.name.split('.');
  const ext = filenameParts[filenameParts.length - 1];

  const ref = `/${applicationId}/complain/${filename}.${ext}`;

  return firebase.storage()
    .ref(ref)
    .put(file, metadata);
}

export function uploadToComplianceFile({ file, metadata, applicationId }) {
  const filename = UUIDGenerator();

  const filenameParts = file.name.split('.');
  const ext = filenameParts[filenameParts.length - 1];

  const ref = `/${applicationId}/statement/${filename}.${ext}`;

  return firebase.storage()
    .ref(ref)
    .put(file, metadata);
}

export function uploadToAdviserFile({ file, metadata, adviserId }) {
  const filename = UUIDGenerator();

  const filenameParts = file.name.split('.');
  const ext = filenameParts[filenameParts.length - 1];

  const ref = `/${adviserId}/${filename}.${ext}`;

  return firebase.storage()
    .ref(ref)
    .put(file, metadata);
}

export function updateFile({ metadata, path }) {
  return firebase.storage()
    .ref(path)
    .updateMetadata(metadata);
}

export async function getFileByApplicationId(applicationId) {
  const files = await firebase.storage()
    .ref(`/${applicationId}/`)
    .listAll();

  return files;
}

export async function getComplianceFileByApplicationId(applicationId) {
  const files = await firebase.storage()
    .ref(`/${applicationId}/statement/`)
    .listAll();

  return files;
}

export async function getComplainFileByApplicationId(applicationId) {
  const files = await firebase.storage()
    .ref(`/${applicationId}/complain/`)
    .listAll();

  return files;
}

export async function getAdviserFileByAdviserId(adviserId) {
  const files = await firebase.storage()
    .ref(`/${adviserId}/`)
    .listAll();

  return files;
}

export function downloadFile(fullPath) {
  return firebase.storage()
    .ref(fullPath);
}

export function archiveFile(file) {
  const customMetadata = {
    filename: file.filename,
    description: file.description,
    status: FILE_STATUS.archive,
    order: file.order,
  };

  return firebase.storage()
    .ref(file.path)
    .updateMetadata({ customMetadata });
}

export function saveOrderFile(file) {
  const customMetadata = {
    filename: file.filename,
    description: file.description,
    status: FILE_STATUS.active,
    order: file.order,
  };

  return firebase.storage()
    .ref(file.path)
    .updateMetadata({ customMetadata });
}

export function setFileMeta(fullPath, newMetadata) {
  return firebase.storage()
    .ref(fullPath)
    .updateMetadata({ customMetadata: newMetadata });
}

export function listAdviserUsers(adviserId) {
  return firebase.firestore()
    .collection('users')
    .where('adviser', '==', adviserId)
    .get();
}

export function advisersList() {
  return firebase.firestore()
    .collection('users')
    .where('role', '==', USER_ROLES.adviser)
    .get();
}

export function createUser(payload) {
  const request = payload;
  request.recovered = false;
  const docRef = firebase.firestore()
    .collection('users')
    .doc();
  return docRef
    .set(request);
}

export function notificationSnapshot(receiver, callback) {
  return firebase.firestore()
    .collection('notifications')
    .where('to', '==', receiver)
    .where('active', '==', true)
    .onSnapshot((querySnapshot) => {
      callback(querySnapshot);
    });
}

export function get(receiver, callback) {
  return firebase.firestore()
    .collection('notifications')
    .where('to', '==', receiver)
    .where('active', '==', true)
    .onSnapshot((querySnapshot) => {
      callback(querySnapshot);
    });
}

export function dismissNotification(notificationId) {
  return firebase.firestore()
    .collection('notifications')
    .doc(notificationId)
    .update({ active: false });
}

export function setSettings(settings) {
  return firebase.firestore()
    .collection('settings')
    .doc('settings')
    .set({ settings });
}

export function setANZdata(excelData) {
  return firebase.firestore()
    .collection('settings')
    .doc('anzData')
    .set(excelData);
}

export async function getANZdata() {
  return firebase.firestore()
    .collection('settings')
    .doc('anzData')
    .get()
    .then((doc) => doc.data());
}

export async function getSettings() {
  return firebase.firestore()
    .collection('settings')
    .doc('settings')
    .get()
    .then((doc) => doc.data());
}

export function setLinkStatus(payload) {
  return firebase.firestore()
    .collection('settings')
    .doc('uploadLink')
    .set(payload);
}

export async function getLinkStatus() {
  return firebase.firestore()
    .collection('settings')
    .doc('uploadLink')
    .get()
    .then((doc) => doc.data());
}
