<template>
  <div>
    <v-form @submit.prevent="submit">
      <h3 class="login-header" data-cy="login-header">LOGIN</h3>
      <v-text-field
        data-cy="email"
        label="Enter your e-mail address"
        v-model="form.email"
        :rules="emailRules"
        required
      ></v-text-field>
      <v-text-field
        data-cy="password"
        label="Enter your password"
        v-model="form.password"
        :rules="passwordRules"
        type="password"
        required
      ></v-text-field>
      <v-layout justify-space-between>
        <v-btn
          :loading="loading"
          @click="submit"
          color="primary"
          data-cy="submit"
        >Login
        </v-btn>
        <a href="">Forgot Password</a>
      </v-layout>
    </v-form>
  </div>
</template>

<script>

export default {
  name: 'BaseLoginEmailPassword',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        email: '',
        password: '',
      },
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => /.+@.+/.test(v) || 'E-mail must be valid',
      ],
      passwordRules: [
        (v) => !!v || 'Password is required',
        (v) => v.length >= 6 || 'Password must be more than 6 characters',
      ],
    };
  },
  methods: {
    submit() {
      this.$emit('login', this.form);
    },
  },
};
</script>

<style scoped>
.login-header {
  color: #1867C0;
}
</style>
