var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h3', {
    staticClass: "login-header"
  }, [_vm._v("Enter the code from message")]), _c('v-form', {
    ref: "form",
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "6-digits code",
      "required": ""
    },
    model: {
      value: _vm.code,
      callback: function callback($$v) {
        _vm.code = $$v;
      },
      expression: "code"
    }
  }), _c('v-layout', {
    attrs: {
      "justify-space-between": ""
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary",
      "type": "submit",
      "loading": _vm.loading
    }
  }, [_vm._v("Confirm")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }