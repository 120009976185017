const capitalize = (string) => {
  const isStringValid = Boolean(string?.length > 0);
  if (isStringValid) {
    const [firstChar] = string;
    const firstCharUpper = firstChar?.toUpperCase();

    const resultString = `${firstCharUpper}${string.slice(1)}`;

    return resultString;
  }

  return string;
};

function dateISOFormatter(value) {
  const dateSplit = value.split('-');

  const year = dateSplit[0];
  const month = parseInt(dateSplit[1], 10) - 1;
  const day = parseInt(dateSplit[2], 10) + 1;

  const dateFormat = new Date(year, month, day);

  const dateISO = dateFormat.toISOString().substr(0, 10);

  return dateISO;
}

function formatBytes(bytes, decimals = 2) {
  const isBytesNotValid = Boolean(Number.isNaN(bytes) || bytes === 0);

  if (isBytesNotValid) {
    return '0 Bytes';
  }

  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const kilo = 1024;
  const formatDecimals = decimals < 0 ? 0 : decimals;

  const index = Math.floor(Math.log(bytes) / Math.log(kilo));

  const size = parseFloat((bytes / kilo ** index).toFixed(formatDecimals));
  const sizeWithUnits = `${size} ${sizes[index]}`;

  return sizeWithUnits;
}

function valueToNum(value) {
  const amount = parseInt(value, 10);
  const isAmount = Boolean(Number.isNaN(amount));
  if (!isAmount) {
    return amount;
  }
  return 0;
}

function numberFormat(number) {
  const numSplit = number.split('.');

  if (numSplit[1] === '00') {
    return Math.round(number);
  }

  if (numSplit[1].slice(-1) === '0') {
    return Math.floor(number * 10) / 10;
  }

  return number;
}

function formatMillions(number) {
  if (Math.abs(Number(number)) >= 1.0e+9) {
    const numFixed = (Math.abs(Number(number)) / 1.0e+9).toFixed(2);

    const result = numberFormat(numFixed);

    return `${result}B`;
  }
  if (Math.abs(Number(number)) >= 1.0e+6) {
    const numFixed = (Math.abs(Number(number)) / 1.0e+6).toFixed(2);

    const result = numberFormat(numFixed);

    return `${result}M`;
  }
  if (Math.abs(Number(number)) >= 1.0e+3) {
    const numFixed = (Math.abs(Number(number)) / 1.0e+3).toFixed(2);

    const result = numberFormat(numFixed);

    return `${result}K`;
  }

  return Math.abs(Number(number));
}

function integerFormatter(str) {
  const formattedNumber = str.replace(/[^-0-9]/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  return formattedNumber;
}

function textFormatter(str) {
  const formattedText = str.replace(/\s|,/g, '');
  return formattedText;
}

export default {
  capitalize,
  dateISOFormatter,
  formatBytes,
  integerFormatter,
  formatMillions,
  textFormatter,
  valueToNum,
};
