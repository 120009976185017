import { getAdviserClients } from '@/services/firebase';

export default {
  namespaced: true,
  state: {
    clients: {},
  },
  getters: {},
  actions: {
    async loadClients(context, adviserId) {
      const adviserClients = {};
      const response = await getAdviserClients(adviserId);
      response.forEach((doc) => {
        adviserClients[doc.id] = doc.data();
      });
      context.commit('SET_CLIENTS', adviserClients);
    },
  },
  mutations: {
    SET_CLIENTS(state, clients) {
      state.clients = clients;
    },
  },
};
